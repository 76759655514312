import React, { Component } from "react"
import { Link } from "gatsby"
import classnames from "classnames"

import "../scss/header.scss"

import HomePageLogo from "../assets/img/cloud_1.svg"
import MenuIcon from "../assets/img/menu-icon.svg"

import { FaTwitter } from 'react-icons/fa'
import { FaFacebookSquare } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'

class Header extends Component {
  constructor(props) {
    super(props)

    this.myRef = React.createRef()

    this.state = {
      isSticky: false,
      showNav: false,
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        this.setState({
          isSticky: true,
        })
      } else if (window.scrollY < 100) {
        this.setState({
          isSticky: false,
        })
      }
    })
  }

  render() {
    const { isSticky, showNav } = this.state

    const hClass = classnames(
      "navbarDiv",
      "sticky-top",
      isSticky ? "compactNav" : this.props.headerTheme
    )

    const togglerClass = classnames(
      "navbar-toggler",
      showNav ? "" : "collapsed"
    )

    const collapseClass = classnames(
      "navbar-collapse",
      "collapse",
      showNav ? "show" : ""
    )

    const checkScroll = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        if (document.body.classList.contains("no-scroll")) {
          document.documentElement.classList.remove("no-scroll")
          return document.body.classList.remove("no-scroll")
        }
        document.documentElement.classList.add("no-scroll")
        return document.body.classList.add("no-scroll")
      }
    }

    const handleNavClick = () => {
      this.setState({ showNav: true })
      checkScroll()
    }

    const handleLinkClick = () => {
      this.setState({ showNav: false })
      checkScroll()
    }

    return (
      <div className={hClass}>
        <nav className="navbar navbar-expand-lg">
          <Link className="navbar-brand" to="/">
            <HomePageLogo style={{ maxHeight: '200px', height: '60px' }} />
          </Link>
          <div className="row justify-content-end mr-18-percent">
            <a
              className={togglerClass}
              type="button"
              data-toggle="collapse"
              data-target="#navbarTop"
              aria-controls="navbarTop"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleNavClick}
            >
              <img src={MenuIcon} />
            </a>

            <div className={collapseClass} id="navbarTop">
              <ul className="mr-auto navbar-nav mt-5 mt-md-0">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    activeClassName="nav-link-active"
                    onClick={handleLinkClick}
                  >
                    <span>Home</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/about-us"
                    activeClassName="nav-link-active"
                    onClick={handleLinkClick}
                  >
                    <span>About Us</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/contact-us"
                    activeClassName="nav-link-active"
                    onClick={handleLinkClick}
                  >
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li className="socials position-absolute d-lg-none">
                  <div className="footerSocials">
                    <a
                      href="https://twitter.com/YoupalGroup"
                      className="mr-4"
                      target="_blank"
                    >
                      <FaTwitter className="footer-icon header-social-icon icon-twitter-hover"/>
                    </a>
                    <a
                      href="https://www.facebook.com/youpalgroup"
                      className="mr-4"
                      target="_blank"
                    >
                      <FaFacebookSquare className="footer-icon header-social-icon icon-facebook-hover"/>
                    </a>
                    <a
                      href="https://www.instagram.com/youpalgroup"
                      className="mr-4"
                      target="_blank"
                    >
                      <FaInstagram className="footer-icon header-social-icon icon-instagram-hover"/>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/youpalgroup"
                      target="_blank"
                    >
                      <FaLinkedin className="footer-icon header-social-icon icon-linkedin-hover"/>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header
