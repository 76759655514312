import React, { useEffect, useState } from "react"
import CookieConsent from "react-cookie-consent"

import Header from "./header"
import Footer from "./footer"
import Background from "./background"
import "../scss/header.scss"

const Layout = ({ path, children }) => {
  const [bgClass, setBgClass] = useState("homeBg")
  const [headerTheme, setHeaderTheme] = useState("lightHeader")

  useEffect(() => {
    setBgClass("homeBg")
    setHeaderTheme("lightHeader")
    if (path) {
      const splits = path.split("/")
      const route = splits[1] !== "products" ? splits[1] : splits[2]

      route ? setBgClass(route.toLowerCase() + "Bg") : setBgClass("homeBg")

      if (["blogBg", "youdataBg"].includes(bgClass)) {
        setHeaderTheme("darkHeader")
      }
    }
  })

  return (
    <>
      <CookieConsent buttonStyle={{ color: "white", borderRadius: "10px" }}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Background bgClass={bgClass} />
      <Header headerTheme={headerTheme} />

      <main role="main">
        <div id="page">{children}</div>

        <footer id="footer">
          <Footer />
        </footer>
      </main>
    </>
  )
}

export default Layout
